.socialLinks {
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  min-height: 35px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  .linkItem {
    padding: 0 var(--spacing-sm);
    list-style: none;

    &:first-of-type {
      padding-left: 0 !important;
    }
    .link {
      display: flex;
      align-items: center;
    }
  }
}
